// App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";

import Footer from "./Components/Footer";
import TeamDetails from "./Pages/TeamDetails";
import Faq from "./Pages/Faq";
import Gallery from "./Pages/Gallery";
import Signup from "./Pages/Signup";
import Signin from "./Pages/Signin";
import Courses from "./Pages/Coureses";
import ItCunsultancy from "./Pages/ItCunsultancy";
import Projects from "./Pages/Projects";
import ProjectDetails from "./Pages/ProjectDetails";

import Contact from "./Pages/Contact";

import Enquiry from "./Components/Enquiry";
import ScrollTopTopButton from "./Components/ScrollToTopButton";
import WhatsAppButton from "./Components/WhatsAppButton";
import CoursesDetails from "./Components/CoursesDetails";
// import Careers from "./Components/Carrers/Careers";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Login/Register";
import Topheader from "./Components/Topheader";
import DisplayJobs from "./Components/Careers/DisplayJobs";
import ApplyJobForm from "./Components/Careers/ApplyJobForm";
import Leftbar from "./Components/Leftbar/Leftbar";
import Dashboard from "./Pages/Dashboards/Dashboard";
import AddMarksheetForm from "./Pages/Marksheets/Marksheet";
import Studentform1 from "./Components/Certificate/Studentform1";
import CertificateDetaile from "./Components/Certificate/CertificateDetaile";
import Jobdescription from "./Components/Careers/Jobdescription";
import ApplicantList from "./Components/Careers/ApplicantList";
// import ViewMarksheets from "./Pages/Marksheets/viewmarksheet";
import ViewMarksheets from "./Pages/Marksheets/viewmarksheet";
import Receipt from "./Pages/Receipt";
import AddReceipt from "./Pages/AddReceipt/Addreceipt";
import EditStudentForm from "./Components/Certificate/EditStudentForm";
import Newecepit from "./Pages/Newecepit";
import EditReceipt from "./Pages/AddReceipt/Editeceipt";
import Feedback from "./Pages/Feedback";
import Student from "./Components/StudentData/Student";
import Studentview from "./Components/StudentData/Studentview";
import StudentDetails from "./Components/StudentData/StudentDetails";
import StudentForm from "./Components/StudentForm";
import E1 from "./Components/E1";
import Specializations from "./Components/Specializations";
import ResetPassword from "./Pages/Login/ResetPassword";
import Popupform from './Components/PopupScroll/PopupScroll/Popupform'

const appsList = [
  {
    appId: 0,
    appName: "AUTOCAD",
    imageUrl: "../images/Autocad-Logo.png",
    imagesUrl: "../Certificate.jpg",
    backinformation: "Widely used for 2D and 3D drafting.",
    category: "MECH",
    describe:
      "AutoCAD is the most prominent designing and drafting software used to create precise 2D drawings and 3D models. AutoCAD Mechanical software helps the engineers to deliver their projects in a shorter time which eventually reduces the project costs and improves profitability. SHAW TECH offers the best classroom trainings in India that helps the learner to get in-depth knowledge of various tools and libraries available in the AutoCAD software.AutoCAD Mechanical is majorly used in manufacturing products and has been the first choice for the learners who want to get involved in the field of designing and constructing machinery products. SHAW TECH has various courses in the field of CAD, CAM & CAE and they always prioritize giving the best quality training to students. Moreover, while taking the coaching classes for AutoCAD Mechanical, students are encouraged to do an Internship and also get an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to AutoCAD",
          "Fundamentals of Technical Drawings",
          "Theory of Projection",
          "GUI of AutoCAD",
        ],
      },
      {
        title: ["Drawing & Annotation tools"],
        module: [
          " Drawing Tools",
          " Drafting Settings",
          "  Modify Tools",
          "Dimensions",
          "  Texts",
          "  Multileader",
          "  Tables",
          "Annotation Style Managers",
        ],
      },
      {
        title: ["Productivity Tools"],
        moduless: [
          "Layer Properties Manager",
          "Working with Layers",
          "Isolate and uninsolate any Layer",
          "Design Centre and Tool Pallets",
          "Create and Edit Blocks",
        ],
      },
      {
        title: ["Advance Tools"],
        moduless1: [
          "Template Creation",
          " Linking and Extractions",
          "  Hatch and Gradients",
          " Parametric Drawings",
          " Isometric drawings",
        ],
      },
    ],
  },

  {
    appId: 1,
    appName: "SOLID WORK",
    imageUrl: " ../images/soildwork.png",
    backinformation: "3D modeling software for mechanical engineering..",
    category: "MECH",
    describe:
      "SOLIDWORKS is powerful 3D CADD software that helps in shortening the design cycle and increases productivity. SOLIDWORKS is the world’s leading product design software that facilitates us to deliver innovative solutions to the engineering or technical industries. SHAW TECH offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Solidworks software.Being a 2D and 3D parametric modeler, SOLIDWORKS is essential to most engineering and drafting systems. SHAW TECH provides software training on a wide range of courses in the field of CAD, CAM & CAE and also they put great emphasis on delivering quality training to students. Further, while taking the coaching classes for SOLIDWORKS, students are encouraged to do Internship and also gets an option to go for industrial visits.",

    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to PDC & CAD/CAM/CAE",
          "Parametric and Non-Parametric Modelling",
          "Introduction to Solidworks",
          "GUI of Solidworks",
        ],
      },
      {
        title: ["Sketch Module"],
        module: [
          "Sketch Entities",
          "Sketch Modifying Tools",
          "Parametric Sketching",
          "Geometric Relations",
          "Dimensional Constraint",
        ],
      },
      {
        title: ["Solid Modelling"],
        moduless: [
          "Creating Base Features",
          "Creating Cut Features",
          "Creating Reference Geometries",
          "Curves in Solidworks",
          "Advanced Modelling Tools",
          "Advanced Fastening Features",
        ],
      },
      {
        title: ["Drawing Module"],
        moduless1: [
          "Adding New Sheets",
          "Editing the Sheet Format",
          "Creating different views from the 3D Model",
          "Create Drawing from Exploded View",
          "Editing and Modifying Drawing Views",
          "Adding Annotations to Drawing Views",
          "Adding Balloons to the Drawing Views",
        ],
      },
    ],
  },
  {
    appId: 2,
    appName: "CATIA",
    imageUrl: "../images/catia.png",
    backinformation: "Comprehensive CAD/CAM/CAE suite for industries.",
    category: "MECH",
    describe:
      "CATIA is the well-known CADD software developed and designed by Dassault Systemes. CATIA is widely used in various industries like automotive, aerospace, auto-manufacturing companies, and also in architectural and construction projects for designing, analyzing, and engineering insights. SHAW TECH offers best class room trainings in India that helps the learner to get in depth knowledge of various tools and features that available in the CATIA software.CATIA helps in developing high quality products. Also it is multi-platform 3D software, that not only just models any product, but it also provides hands-on experience on the product as per the real world. SHAW TECH provides best quality trainings to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes for CATIA, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to PDC & CAD/CAM/CAE",
          "Introduction to CATIA V5",
          "GUI of CATIA V5",
        ],
      },
      {
        title: ["Sketcher workbench"],
        module: [
          "Profile Toolbar",
          "Sketch Tools",
          "Operations",
          "Constraints",
        ],
      },
      {
        title: ["Part Design Workbench"],
        moduless: [
          "Sketch-based Features",
          "Dress-up Features",
          "Reference Elements",
          "Transformation Features",
          "Boolean Operations",
          "Material and Rendering",
        ],
      },
      {
        title: ["Assembly Modelling"],
        moduless1: [
          "Introduction to Assembly",
          "Top-Bottom and Bottom-Up approach",
          "Product Structure Tools",
          "Constraints",
          "Applying Constraints",
          "Interference Detection",
        ],
      },
    ],
  },
  {
    appId: 3,
    appName: "NXCAD",
    imageUrl: "../images/nxcad.png",
    backinformation: "Advanced CAD/CAM/CAE software by Siemens.",
    category: "MECH",
    describe:
      "Siemens NX is a progressive high-end CAD/CAM/CAE software package that is widely used for mechanical product design, engineering, and manufacturing. NX CAD comes up with such a prominent designing tool that is widely used in industry and is setting high standards for speed performance. SHAW TECH offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the NX CAD software.NX CAD works seamlessly with data as compare to other CAD systems and it indulges the designers to get quick results by doing more use of virtual product models and physical prototypes. SHAW TECH provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes for NX CAD, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction NXCAD",
          "CAD/CAM/CAE",
          "Basic Layout NX CAD and its Uses",
          "Advantages",
        ],
      },
      {
        title: ["Sketcher Module"],
        module: [
          "Sketch tools",
          "Sketch Task environment",
          "Sketch different type of curve",
        ],
      },
      {
        title: ["Part Module"],
        moduless: [
          "Creating Solid Feature",
          "Creating Cut Feature",
          "Creating Boolean Operation",
          "Create editing feature",
          "Create pattern",
          "Create basic block solid bodies",
          "Create datum plane",
        ],
      },
      {
        title: ["Assembly Modelling"],
        moduless1: [
          "Introduction to Assembly",
          "Top-Bottom and Bottom-Up approach",
          "Product Structure Tools",
          "Constraints",
          "Applying Constraints",
          "Interference Detection",
        ],
      },
    ],
  },

  {
    appId: 4,
    appName: "CREO",
    imageUrl: "../images/creologo.png",
    backinformation: "Parametric 3D modeling software for engineering.",
    category: "MECH",
    describe:
      "Creo is a 3D CAD software developed by PTC Corporation. CREO is a powerful software that takes care of all the aspects of product design including product development, designing, and analysis. SHAW TECH classroom trainings helps the students to get best software training and let them understand all the important tools and commands of the software.Creo is an easy to learn software that reduces the costs and seamlessly takes you from product designing phase to manufacturing phase very quickly and also improves product quality. With the most experienced trainers, SHAW TECH deliver many software trainings in the fields of CAD, CAM & CAE that are best in the industry. While learning from best software courses, students get an option to do Internship and can also go for Industrial visits assisted by the trainers; so that it helps the students to escalate their career and let them have in-hand knowledge of the software.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "CAD/CAM/CAE",
          "About of Creo Parametric Software",
          "Set Working Directory",
          "Interface of Creo and View Toolbar",
        ],
      },
      {
        title: ["Sketcher Module"],
        module: [
          "Creating Sketch Geometry & Construction Geometry",
          "Creating Editing Tools",
          "Creating Dimension and Constraints",
          "Understand Inspect Tools",
        ],
      },
      {
        title: ["Part Module"],
        moduless: [
          "Creating Solid Feature",
          "Creating Cut Feature",
          "Creating Boolean Operation",
          "Create editing feature",
          "Create pattern",
        ],
      },
      {
        title: ["Surface Module"],
        moduless1: [
          "Using shape feature tools",
          "Using blend tangent to tools",
          "Creating and editing tools",
          "Developing freeform surface model",
        ],
      },
    ],
  },
  {
    appId: 5,
    appName: "AUTO INVENTOR",
    imageUrl: "../images/autoinvernter.png",
    backinformation: " Professional-grade 3D mechanical design software.",
    category: "MECH",
    describe:
      "Autodesk Inventor is a professional-grade three-D CAD software program for mechanical engineers that gives all middle CAD features, together with parametric design, pressure analysis, and sheet steel design. It is used to create parts, assemblies, and drawings for production and production. In addition to product design,its also first-class desirable for tooling advent and simulation to check a product in movement earlier than constructing it.Inventor is broadly favored for developing correct three-D fashions and visualisation.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "CAD/CAM/CAE",
          "About of Creo Parametric Software",
          "Set Working Directory",
          "Interface of Creo and View Toolbar",
        ],
      },
      {
        title: ["Sketcher Module"],
        module: [
          "Creating Sketch Geometry & Construction Geometry",
          "Creating Editing Tools",
          "Creating Dimension and Constraints",
          "Understand Inspect Tools",
        ],
      },
      {
        title: ["Part Module"],
        moduless: [
          "Creating Solid Feature",
          "Creating Cut Feature",
          "Creating Boolean Operation",
          "Create editing feature",
          "Create pattern",
        ],
      },
      {
        title: ["Surface Module"],
        moduless1: [
          "Using shape feature tools",
          "Using blend tangent to tools",
          "Creating and editing tools",
          "Developing freeform surface model",
        ],
      },
    ],
  },
  {
    appId: 6,
    appName: "ANSYS",
    imageUrl: "../images/ansys.png",
    backinformation: "Simulation software for engineering analysis tasks.",
    category: "MECH",
    describe:
      "ANSYS Workbench is a vastly used general-purpose engineering analysis that uses virtual simulation technology to solve many different engineering problems in the industries. ANSYS supports delivering high quality products with the help of simulation and also provides better results in lesser time. SHAW TECH delivers the best classroom training for ANSYS WB, which has been designed in such a way that it will give a complete understanding and working of various tools in the software.ANSYS WB training course let the learner understand not only the basics of ANSYS but also highlights the concepts of Finite Element Analysis (FEA) and Finite Element Modeling (FEM). SHAW TECH delivers the best CAD, CAM, and CAE software training in India. While doing the software training, students get an option to do Internship and develop their skills by working on projects. Also, they can go on various industrial visits with their trainers to gain practical knowledge of their software, which eventually helps them in building their career.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to ANSYS WB",
          "Introduction to FEA",
          "GUI of ANSYS WB",
          "General Procedure of Conducting FEA",
          "Types of Analysis",
        ],
      },
      {
        title: ["Engineering Data"],
        module: [
          "Adding materials into the current library",
          "Adding new material",
        ],
      },
      {
        title: ["Analysis Types"],
        moduless: ["1D Analysis", "2D Analysis", "3D Analysis"],
      },
      {
        title: ["Analysis Systems"],
        moduless1: [
          "Defining Manual Contacts",
          "Defining Joints and Joints Loads",
          "Static Structural Analysis",
          "Modal Analysis",
          "Steady-State Thermal Analysis",
        ],
      },
    ],
  },
  {
    appId: 7,
    appName: "REVIT MEP",
    imageUrl: "../images/revit.png",
    backinformation: "Building design software for mechanical engineers.",

    category: "MECH",
    describe:
      "Autodesk Revit MEP is the industry-standard tool designed for building information modeling or BIM. MEP expands as Mechanical, Electrical, and Plumbing, which are the major components of the BIM chain. By joining SHAW TECH classroom training for Revit MEP, students get an opportunity to learn from our well experienced trainers; who gives a great emphasis on delivering quality training to each of its learners.MEP project template in Revit is designed in such a way that it helps the learner to understand the working and uses of ducting, mechanical equipment, plumbing, electrical fixtures in a building. SHAW TECH provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Revit MEP, students are encouraged to do an Internship and also get an opportunity to go for industrial visits.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to Revit Structure",
          "Project Templates",
          "Exploring the User Interface",
          "Starting a Project",
          "Linking Architecture",
        ],
      },
      {
        title: ["Structure Design"],
        module: [
          "Creating Levels",
          "Creating View Templates",
          "Adding and Placing Steel Columns",
        ],
      },

      {
        title: ["Analysis Systems"],
        moduless: [],
      },

      {
        title: ["Post Processing"],
        moduless1: [""],
      },
    ],
  },
  {
    appId: 8,
    appName: "CNC",
    imageUrl: "../images/cnc.png",
    backinformation: "Automated machining control system for manufacturing.",

    category: "MECH",
    describe:
      "CNC programming (Computer Numerical Control Programming) is the software that is beneficial for creating computer program instructions to control machine tools. CNC is vastly used in the manufacturing process and promotes good mechanisms as well as flexibility. By joining SHAW TECH classroom training for CNC Programming, students get an opportunity to learn from our expert trainers; who give a great emphasis on delivering the best quality training to each of its learners.Using CNC, a machine can be managed with supervision and it will increase productivity and minimize the risk of getting errors on the project. SHAW TECH delivers the best CAD, CAM, and CAE software training in India. While doing the software training, students get an option to do Internship and develop their skills by working on projects. Also, they can go on various industrial visits with their trainers to gain practical knowledge of their software, which eventually helps them in building their career.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to ANSYS WB",
          "Introduction to FEA",
          "GUI of ANSYS WB",
          "General Procedure of Conducting FEA",
          "Types of Analysis",
        ],
      },
      {
        title: ["Engineering Data"],
        module: [
          "Adding materials into the current library",
          "Adding new material",
        ],
      },
      {
        title: ["Analysis Types"],
        moduless: ["1D Analysis", "2D Analysis", "3D Analysis"],
      },
      {
        title: ["Analysis Systems"],
        moduless1: [
          "Defining Manual Contacts",
          "Defining Joints and Joints Loads",
          "Static Structural Analysis",
          "Modal Analysis",
          "Steady-State Thermal Analysis",
        ],
      },
    ],
  },
  {
    appId: 9,
    appName: "ART CAM",
    imageUrl: "../images/artcam.png",
    backinformation: "Software for artistic design and manufacturing.",

    category: "MECH",
    describe:
      "ArtCAM is a group of CAD/CAM software program bundle for designing innovative and inventive merchandise and manufacturing. It is a CAM laptop software particularly used while the consumer works on wooden engraving machines or comparable machines. 3-d Archlab on-line education path explains approximately 2D and 3-d built in layout functions which include reliefs, engraving, signal making, etc.ArtCAM is enormously encouraged software program in quite a few sectors from jewelry and coin minting, to the layout and manufacture of architectural cornicing and facades. 3-d Archlab additionally gives Internships to its college students and additionally includes on Industrial visits all through the path. Online software program education is supplied at the ultra-modern model of ArtCAM.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "introductiondesign",
          "introduction Art cam",
          "fundamental of techanical drawing",
          "theory of projection",
          "GUI of art cam",
        ],
      },
      {
        title: ["Modules"],
        module: [
          "Drawing",
          "modifytools",
          "dimensions",
          "texts",
          "multipleader",
          "productivity",
          "advance tools",
          "3d modeling",
        ],
      },
    ],
  },
  {
    appId: 10,
    appName: "NX CAM",
    imageUrl: "../images/nxcam.png",
    backinformation: "Advanced CAM software for machining tasks.",

    category: "MECH",
    describe:
      "Siemens NX is an advanced high-end CAD/CAM/CAE software package that is used for mechanical product design, engineering, and manufacturing.  It is the most flexible and innovative product design application used for product development solutions in the industry. SHAW TECH provides classroom training for NX CAM through which students get an opportunity to learn from our expert trainers; who give a great emphasis on delivering the best quality training to each of its learners.NX CAM uses the most consistent 3D models, data, and processes that produce complex parts with fewer actions and setups and eventually reduces the costs and delivery times. SHAW TECH provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for NX CAM, students get motivated to do Internship and also gets an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "introduction design",

          "fundamental of techanical drawing",
          "theory of projection",
          "GUI Nx cam",
        ],
      },

      {
        title: ["Modlues"],
        module: [
          "Drawing",
          "modifytools",
          "dimensions",
          "texts",
          "multipleader",
          "productivity",
          "advance tools",
          "3d modeling",
        ],
      },
    ],
  },
  {
    appId: 11,
    appName: "AUTOCAD",
    imageUrl: "../images/Autocad-Logo.png",
    backinformation: "Widely used for 2D and 3D drafting.",
    category: "CIVI",
    describe:
      "AutoCAD Civil 3D software is used in civil engineering that enables building information modeling (BIM) workflows. Civil 3D is a great software that boosts up project performance, maintains consistent data, and follows standard processes. SHAW TECH offers the best classroom training for Civil 3D through which students understand the surfaces, lay out parcels, and design geometry by including the making of horizontal alignments and vertical profile, and many more.AutoCAD & Civil 3D both have similar interfaces and Civil 3D also has many AutoCAD compatible shortcuts available. SHAW TECH provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training, students are encouraged to do an Internship and also get an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "introduction Autocad",
          "fundamental of techanical drawing",
          "theory of projection",
          "GUI Auto cad",
        ],
      },
      {
        title: ["Modules"],
        module: [
          "Drawing",
          "modifytools",
          "dimensions",
          "texts",
          "multipleader",
          "productivity",
          "advance tools",
          "3d modeling",
        ],
      },
    ],
  },
  {
    appId: 12,
    appName: "REVIT ARCH",
    imageUrl: "../images/revit.png",
    backinformation: " BIM software for architectural design projects.",

    category: "CIVI",
    describe:
      "Autodesk Revit is the industry-standard tool designed for building information modeling or BIM. Revit Architecture training helps in enhancing the efficiency and accuracy across the project lifecycle, from concept to construction and later maintenance. As the model develops, Revit automatically updates the floor plans, elevations, and sections. SHAW TECH offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Revit Architecture software. Revit Architecture is a 3D designing software that enables the design of building structure and its components and further effectively analyzes the structure. SHAW TECH provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes for Revit Architecture, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",

    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to Revit Architecture",
          "Project Templates",
          "Exploring the User Interface",
          "Starting a Project",
        ],
      },
      {
        title: ["Building Panel"],
        module: [
          "Create Wall & Curtain Walls",
          "Doors & Windows",
          "New Component",
          "Floors & Roofs",
        ],
      },

      {
        title: ["Annotation Module"],
        moduless: ["Dimensions", "Texts", "Model Texts"],
      },

      {
        title: ["Room and Area Panel"],
        moduless1: [
          "Room Plans and Separation",
          "Area Boundaries",
          "Area Tags",
          "Colour fill legend",
        ],
      },
    ],
  },

  {
    appId: 13,
    appName: "SKETCH UP",
    imageUrl: "../images/sketchup.png",
    backinformation: "3D modeling software for architectural visualization.",

    category: "CIVI",
    describe:
      "SketchUp (formerly known as Google SketchUp) is a great tool to create 3D models in architecture, interior design, woodworking, or engineering. It has quite a user-friendly interface as compare to 3DS Max, with a splendid user environment than other computer-aided design programs. SHAW TECH offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Google SketchUp software. SketchUp is a built-in 3D modeling application that lets us create and edit 2D and 3D models with a patented “Push/Pull” technology and it is widely preferred by construction owners, architects, and engineers for error-free work output. SHAW TECH provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to Sketch Up",
          "  The Space Navigator 3D Mouse Types of Models",
          "Save Options",
          "   Creating text",
          "  Backup Files",
        ],
      },
      {
        title: ["Exploring the interface"],
        module: [
          " Select or Change a Template",
          " Add the Large Tool Set",
          "The Select Tool",
          "  Modifier Keys",
          " The Views Toolbar: Generate Orthographic Drawings",
        ],
      },

      {
        title: [" Modeling A House Interior"],
        moduless: [
          " Model the Shell and First Floor",
          " Model the Second Floor",
          "  Model the Living Room",
          " Model a Cabinet",
          " Model Crown Molding with the Follow Me Tool",
        ],
      },

      {
        title: [" Drawing Module"],
        moduless1: [
          "  Introduction of Drawing",
          " Creating New Drawing",
          "    Creating Projection Views",
          " Creating Sectional Views",
          " Creating Note and Annotation",
        ],
      },
    ],
  },
  {
    appId: 14,
    appName: "REVIT STRU",
    imageUrl: "../images/revitstructure.png",
    backinformation: "BIM software for structural engineering projects.",

    category: "CIVI",
    describe:
      "Revit Structure is a powerful platform or software in structural engineering, provides great exploration for cutting-edge tools and for creating design alternatives, building simulations, and publishing. . By joining SHAW TECH classroom training for Revit Structure, students get an opportunity to learn from our expert trainers; who gives a great emphasis on delivering best quality training to each of its learners.Revit structure Training includes BIM software for structural engineering which enhances structural design coordination, minimizes errors. Also, it helps in increasing overall efficiency, profitability, and ease the outcomes of high quality. SHAW TECH delivers the best CAD, CAM, and CAE software training in India. While doing the software training, students get an option to do Internship and develop their skills by working on projects. Also, they can go on various industrial visits with their trainers to gain practical knowledge of their software, which eventually helps them in building their career.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to Revit Structure",
          " Project Templates",
          "     Exploring the User Interface",
          "   Starting a Project",
          "  Linking Architecture",
        ],
      },
      {
        title: ["Structure Design"],
        module: [
          "Creating Levels",
          "        Creating View Templates",
          "     Adding and Placing Steel Columns",
          "  Creating foundations",
          "        Designing Retaining Walls",
        ],
      },

      // {
      //   title: [" Modeling A House Interior"],
      //   module: [

      //    " Model the Shell and First Floor",
      //    " Model the Second Floor",
      //   "  Model the Living Room",
      //    " Model a Cabinet",
      //    " Model Crown Molding with the Follow Me Tool",
      //   ],
      // },

      // {
      //   title: [" Drawing Module"],
      //   module: [

      //   "  Introduction of Drawing",
      //    " Creating New Drawing",
      // "    Creating Projection Views",
      //    " Creating Sectional Views",
      //    " Creating Note and Annotation",]
      // },
    ],
  },
  {
    appId: 15,
    appName: "V-RAYS",
    imageUrl: "../images/vrays.png",
    backinformation: " Rendering software for high-quality visualizations.",

    category: "CIVI",
    describe:
      "V-Ray is the complete lighting, shading, and rendering toolkit using which you can render anything & everything. V-Ray is a commercial plug-in, used for visualizations and computer graphics in industries such as media, entertainment, film and video game production, industrial design, product design, and architecture. SHAW TECH offers the best classroom training in India that helps the learner to get in depth knowledge of various tools and features available in the V-Ray software.Due to the usage of advanced techniques, V-Ray is highly recommended in multiple industries such as architectural visualization, advertising, and visual effects, etc., and is most commonly preferred by conventional renders. SHAW TECH provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training, students are encouraged to do an Internship and also get an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "  Getting Ready to Render with V-Ray",
          " Locating V-Ray’s tools and features",
          "V-Ray’s image-saving options ",
        ],
      },
      {
        title: [" Critical V-Ray Concepts"],
        module: [
          " Image sampling explained",
          " Understanding subdivisions",
          " Using the DMC Sampler",
          "  Overview of color mapping",
          "    Understanding the color-mapping modes",
        ],
      },

      {
        title: ["   Annotation Module"],
        moduless: [
          "   Introduction to lighting in V-Ray",
          "        Dealing with lighting problems",
          "Adding a spherical fill light",
          " Creating a mesh light",
          "  Creating a skylight effect",
          "  Working with the dome light",
        ],
      },

      {
        title: ["     Global Illumination"],
        moduless1: [
          "  Global illumination (GI) explained",
          " Understanding primary and secondary bounces",
          "   How irradiance mapping works",
          "    Using irradiance mapping, part 1",
          " Using irradiance mapping, part 2",
          "How light cache works",
        ],
      },
    ],
  },
  {
    appId: 16,
    appName: "LUMION",
    imageUrl: "../images/lumion.png",
    backinformation: "Real-time 3D visualization software for architects.",

    category: "CIVI",
    describe:
      " Lumion is 3D rendering software for architects. Stable, fast, and intuitive, this tool helps you maintain your creative flow and transform architecture, interior, landscape, and urban design into high-quality visualizations. Whether you are a complete beginner or a rendering pro, Lumions user-friendly interface makes it easy to import your 3D models and bring them to life. Compatible with Revit, 3ds Max, SketchUp, ArchiCAD, Rhino, and more,it fits seamlessly into your workflow and transforms any model into a haptic experience. Lumion allows you to iterate during construction and quickly integrate visualizations at every stage of design development thanks to its lightning-fast rendering speeds. Everything you need to create stunning images, fly-through videos, and 360-degree panoramas of your designs, including realistic objects and materials, time-saving tools, and one-touch effects.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          " Lumion Overview",
          "Features and Use",
          "        User Interface",
          "Views Modes",
          "Loading and importing Models",
          "Objects and Components Overview",
        ],
      },
      {
        title: [" Object Operations"],
        module: ["  Scaling", " Moving", " Rotation", " Selection", " Setting"],
      },

      {
        title: ["    Environment Settings"],
        moduless: [" Sunset", "Directions", "Landscape", " modification"],
      },

      {
        title: ["     Materials Overview"],
        moduless1: ["Introduction to Material ", "Customisation"],
      },
    ],
  },
  {
    appId: 17,
    appName: "STAAD PRO",
    imageUrl: "../images/staadpro.png",
    backinformation: "Structural analysis and design software tool.",

    category: "CIVI",
    describe:
      "STAAD Pro stands for Structural Analysis and Design Program originally developed by Research Engineers International, which was later bought by Bentley STAAD Pro training is widely recommended in Structural Analysis and Design Software which simplifies the BIM workflow by automatically converting a physical model into an analytical model. SHAW TECH classroom training helps the students to get the best software training and lets them understand all the important tools and commands of the software.STAAD Pro is the most powerful software that can design almost any kind of civil engineering structure such as steel, concrete, timber, aluminum, and cold-formed steel designs. The newly released or complete package of analysis from Bentley is STAAD Pro V8i. With the most experienced trainers, SHAW TECH delivers many software training in the fields of CAD, CAM & CAE that are the best in the industry. While learning from the best software courses, students get an option to do an Internship and can also go for Industrial visits assisted by the trainers; so that it helps the students to escalate their career and let them have in-hand knowledge of the software.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Overview of Structural Analysis and Design",
          " Introduction to STAAD Pro.",
          "  STAAD Pro. Workspace",
          "  STAAD Pro. Interface",
          " Coordinate Systems",
          "        Global Coordinate System",
        ],
      },
      {
        title: ["   Geometry Creation"],
        module: [
          " Geometry Creation Methods",
          " Snap & Grid Method",
          " Copy Cut Method",
          " Run Structure Wizards",
        ],
      },

      {
        title: ["      Loading and Load Calculation"],
        moduless: [
          " Loading",
          " Nodal Load",
          " Nodal Moment",
          " Member Load",
          "   Area Load",
        ],
      },

      {
        title: ["   RCC Design As per IS 456"],
        moduless1: [,],
      },
    ],
  },
  {
    appId: 18,
    appName: "ETAB",
    imageUrl: "../images/etabs.png",
    backinformation: "Structural analysis and design software solution.",

    category: "CIVI",
    describe:
      "ETABS expands as Extended3D Analysis of Building System. ETABS is a structural analysis and design software developed by Computers and Structures, Inc. (CSI). ETABS is an Engineering software application that is highly used for multi-storied building structural analysis as well as structural design. SHAW TECH offers the best classroom training for Civil 3D through which students understand the surfaces, lay out parcels, and design geometry by including the making of horizontal alignments and vertical profile, and many more.ETABS has powerful CAD-like drawing tools available with an integrated system, which enhances its ability to handle the largest and even the complex building models and configurations. SHAW TECH provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Etabs, students get motivated to do Internship and also gets an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to ETABS",
          " Introduction to Structure Design",
          " Drawing Tools",
        ],
      },
      {
        title: ["    Modules"],
        module: [
          " Select Commands",
          "CAD Import",
          "Composite Columns",
          " Wall Modeling",
        ],
      },

      {
        title: ["     Features"],
        moduless: [
          "Shear Wall Design and Optimization",
          "Steel Connection Design",
          " Programming ETABS using the OAPI",
          " Occupancy Loads using Shell Uniform Load Sets",
        ],
      },

      {
        title: ["     Uses"],
        moduless1: [
          "Creating Reports",
          " Buckling Factors",
          " Time History Plots due to Walking Excitation",

          ,
        ],
      },
    ],
  },
  {
    appId: 19,
    appName: "CIVIL 3D",
    imageUrl: "../images/civil3d.png",
    backinformation: "Civil engineering design and documentation software.",

    category: "CIVI",
    describe:
      "Autodesk 3DS Max is a powerful software and is highly recommended among modeling and rendering tools. 3DS Max especially shows its strength in architecture, manufacturing, industrial design, and many more like visual graphics models. SHAW TECH offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the 3DS Max software.During the initial days, 3DS Max was known as a 3D Studio and 3D Studio Max. With its artist-friendly user interface, it saves a lot of time and accelerates manual steps that boost productivity. 3DS Max works is widely used in designing 2D cross-section shapes of 3D models. SHAW TECH provides software training on a wide range of courses in the field of CAD, CAM & CAE and they put great emphasis on delivering quality training to students. Further, while taking the coaching classes for 3DS Max, students are encouraged to do an Internship and also get an option to go for industrial visits.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "         Introduction to SHAW TECH",
          "  Navigating the User Interface",
          "  Application workspace and preferences",
          "    Navigating in viewport",
        ],
      },
      {
        title: ["     Modeling"],
        module: [
          " Standard and Extended Primitives",
          " Modelling with primitives and modifiers",
          "Grid and Snap",
          " Managing Layers",
          "  Boolean & Pro-Boolean",
        ],
      },

      {
        title: ["Components Creation"],
        moduless: [
          "Creating a Bed",
          " Sofa set with Dinning table",
          "  Table & Chair",
          " Doors & Windows",
          "Stairs etc",
        ],
      },

      {
        title: ["     Rendering"],
        moduless1: [
          "  Lighting with photometric and daylight",
          "Shading surfaces with materials",
          " Mapping textures,",

          ,
        ],
      },
    ],
  },

  {
    appId: 20,
    appName: "PRIMAVERA",
    imageUrl: "../images/primvera.png",
    backinformation: "Project management software for construction projects.",

    category: "CIVI",
    describe:
      "Primavera is a project, program, and portfolio control software. It belongs to Oracle Corporation. Primavera is broadly used for making plans, managing, and executing initiatives of all kinds. In architectural and civil engineering, it's miles used to address massive and small production initiatives. Some of its foremost packages are in making plans and scheduling, useful resource control, and chance and trouble control.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "         Introduction to SHAW TECH",
          "  Navigating the User Interface",
          "  Application workspace and preferences",
          "    Navigating in viewport",
        ],
      },
      {
        title: ["     Modelues"],
        module: [
          " Timelines ",
          "Risk management ",
          "Informative dashboard",
          "Reporting and analytics",
          " Resource Analysis & Allocation",
        ],
      },

      {
        title: ["control projects"],
        moduless: [
          "Plan, schedule, and control projects",
          "Assign resources to tasks, and track progress",
          "Monitor and visualize project performance versus plan",
        ],
      },
    ],
  },
  {
    appId: 21,
    appName: "AUTOCAD",
    imageUrl: "../images/Autocad-Logo.png",
    backinformation: "Widely used for 2D and 3D drafting.",
    describe:
      "AutoCAD Electrical is an Autodesk software specially made for Electrical, Instrumentation and Control System Designers",
    category: "ELEC",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          " auto cad electric indution Automatic Reports",
          "Title Block Update",
          " various import and export utilities",
          " Circuit Builder, and more",
        ],
      },
      {
        title: ["     Modelues"],
        module: [
          " Timelines ",
          "Risk management ",
          "Informative dashboard",
          "Reporting and analytics",
          " Resource Analysis & Allocation",
        ],
      },

      {
        title: ["control projects"],
        moduless: [
          "Plan, schedule, and control projects",
          "Assign resources to tasks, and track progress",
          "Monitor and visualize project performance versus plan",
        ],
      },
    ],
  },
  {
    appId: 22,
    appName: "AUTO ELECTRIC",
    imageUrl: "../images/autoelectric.png",
    backinformation: " Software for automotive electrical systems.",

    category: "ELEC",
    describe:
      "AutoCAD Electrical is one of the most dominant software programs developed by Autodesk which is widely used in creating and modifying electrical control systems. It has a complete toolset available which includes a number of drafting commands like wiring, circuiting, PLC modules, panels, and many more. The Electrical toolset boosts up the productivity level by 95% as compared to other traditional 2D drafting methods. SHAW TECH offers the best classroom training in India that helps the learner to get in depth knowledge of various tools and features available in the AutoCAD Electrical software.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          " AutoCAD Electrical",
          " AutoCAD Electrical Interface",
          " Need of Electrical drawing",
          "  Circuit diagram",
          "  Wiring diagram",
          "Block diagram",
          " Electrical Symbols",
        ],
      },
      {
        title: ["    Schematic Tab"],
        module: [
          "Inserting and Editing components",
          "Inserting and Editing wires",
          "Inserting and Editing circuits",
          "Inserting ladders",
          "Internal jumpers",
        ],
      },

      {
        title: ["Panel Tab"],
        moduless: [
          "  Introduction Panel layout",
          "  Inserting Parametric PLC’s",
          " Inserting connectors",
          "Inserting terminals",
          " Icon menu for the pane",
        ],
      },

      {
        title: ["    Report Tab"],
        moduless1: [
          "Component report",
          "Missing catalog report",
          "Electrical audit",

          ,
        ],
      },
    ],
  },
  {
    appId: 23,
    appName: "EPLAN",
    imageUrl: "../images/elpan.png",
    backinformation: " Electrical engineering software for circuit design.",

    category: "ELEC",
    describe:
      "EPLAN Electric P8 is an electrical engineering design software program that offers unlimited possibilities for project planning, documentation, and management. EPLAN Electric P8 supports a variety of engineering methods due to which it is one of the most preferred software in industries like maritime, building, automation technology, machinery, and plant construction, and more. SHAW TECH delivers the best classroom training for Eplan Electric, which has been designed in such a way that it will give a complete understanding and working of various tools in the software.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Fundamentals of Power System Analysis",
          "  Design and Simulate Electrical Power System",
          "Creating a New Project",
          "  Understanding ETAP Software Overview & Toolbars",
          " Create a Single Line Diagram",
        ],
      },
      {
        title: ["    Modules"],
        module: [
          "  Load Flow Analysis",
          "  Cable Sizing Analysis",
          " Transformer MVA Sizing",
          " Generator Load Sharing",
        ],
      },

      {
        title: ["Features"],
        module: [
          "      Transformer X/R Ratio Calculation",
          "Short Circuit Analysis",
          " Protection Coordination",
          " Arc Flash Analysis",
        ],
      },

      {
        title: ["   Uses"],
        moduless: [
          "   Motor Starting Analysis",
          "Transient Stability Analysis",
          " Harmonic Analysis",
          "       Ground Grid Analysis,",

          ,
        ],
      },
    ],
  },
  {
    appId: 24,
    appName: "MATLAB",
    imageUrl: "../images/matlab.png",
    backinformation: "High-level programming language and numerical computing.",

    category: "ELEC",
    describe:
      "MATLAB is an abbreviation of Matrix Laboratory which is developed by MathWorks for providing a platform for engineers and scientists to perform numerical computing. MATLAB has a worldwide presence and is highly used in various backgrounds of engineering, science, and economics. SHAW TECH classroom training helps the students to get the best software training and lets them understand all the important tools and commands of the software.MATLAB provides an easy-to-use environment for math and computation, algorithm development, data analysis, scientific and engineering graphics, and exploration and visualization. Also, MATLAB is compatible to work on Windows, macOS, and Linux operating systems. SHAW TECH provides software training on a wide range of software in the field of CAD, CAM & CAE and also they put great emphasis on prioritizing in giving quality training to students. Further, while taking the coaching classes for MATLAB, students are encouraged to do Internship and also gets an option to go for industrial visits.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          " Working with the MATLAB user interface",
          "Overview of MATLAB syntax",
          "  Entering commands",
          " Creating variables",
          "  Analyzing vectors and matrices",
          "Visualizing vector and matrix data",
        ],
      },
      {
        title: ["    Modules"],
        module: [
          " Working with data files",
          " Working with data types",
          "Automating commands with scripts",
          " Writing programs with branching and loops",
          " Writing functions",
          " Applying object-oriented programming principles to your programs",
        ],
      },

      {
        title: [" Accessing data"],
        moduless: [
          "  Accessing data",
          " Exploring data",
          "Creating customized algorithms",
          "Creating visualizations",
          " Creating models",
          " Publishing customized reports",
        ],
      },

      {
        title: ["  Analysis tools "],
        moduless1: [
          " Sharing analysis tools",
          "Using the Statistics and Machine Learning Toolbox",
          " Using the Neural Network Toolbox",
          "Creating reports interactively",
          ,
        ],
      },
    ],
  },
  {
    appId: 25,
    appName: "PLC",
    imageUrl: "../images/plc.png",
    backinformation: "Programmable Logic Controller for industrial automation.",

    category: "ELEC",
    describe:
      "Programmable logic controllers (PLCs) are one of the most useful technologies in the industries for automation. They are industrial computer systems that are used to control the electro-mechanical processes in various industries like manufacturing, plants, etc. SHAW TECH provides classroom training for PLC, through which students get an opportunity to learn from our expert trainers; who give a great emphasis on delivering the best quality training to each of its learners. PLCs are available in varied sizes and forms. PLC provides the ability to reprogram the process at very little cost. Programmable logic controllers are flexible and depending on the input and output, it records the data, such as machine productivity or operating temperature. SHAW TECH provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Introduction to Industrial Automation",
          " Application of Industrial Automation",
          " History of Industrial Automation",
          "Introduction to Programmable Logic Controller(PLC)",
        ],
      },
      {
        title: ["  Modules"],
        module: [
          "Block diagram of PLC",
          " PLC Hardware & Architecture",
          "  Wiring Different field Devices to PLC",
          "Addressing for PLC.",
          "   Introduction to the programming language of PLC",
        ],
      },

      {
        title: [" Features"],
        moduless: [
          " Advantages of PLC",
          "NO/NC concept of PLC.",
          "Download PLC program",
          "Upload PLC program",

          "Timers",
          " Counters",
        ],
      },

      {
        title: [" Instructions "],
        moduless1: [
          " Compare/Move",
          "  Mathematical",
          " Logical",
          " Jump/subroutine/MCR",
          " FIFO/LIFO",
          "  Advance Instruction",
          ,
        ],
      },
    ],
  },
  {
    appId: 26,
    appName: "SCADA",
    imageUrl: "../images/scada.png",
    backinformation: "Supervisory Control and Data Acquisition system.",

    category: "ELEC",
    describe:
      "Supervisory Control and Data Acquisition (SCADA) is an automation control system that is widely used in various industries like energy, oil, and gas, water, power, etc. SCADA helps in monitoring and controlling various processes in industries. By joining SHAW TECH classroom training for Scada, students get an opportunity to learn from our well experienced trainers; who give a great emphasis on delivering quality training to each of its learners.SCADA systems contain both hardware and software components and it gathers data from different sensors and systems located in remote areas, and further performs the analysis. The hard disk present in the SCADA system, stores the data into files, which can be printed whenever required. SHAW TECH provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Scada, students are encouraged to do an Internship and also get an opportunity to go for industrial visits.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          " Introduction to SCADA Systems",
          "SCADA -What is it?",
          "Old SCADA Advantages and The Disadvantages",
          "Modern Automation Systems",
        ],
      },
      {
        title: ["  Introduction to SCADA Systems"],
        module: [
          " History",
          "  Why SCADA? What is SCADA?",
          "   Classifications",
          "Elements of SCADA",
          " Sensors",
          " Actuators",
          " RTUs",
        ],
      },

      {
        title: ["  Review on PLC Systems"],
        moduless: [
          "PLC Architecture and Operation",
          "Commonly-used instructions",
          " Numbering systems",
          " I/O systems and PLCs wiring",
        ],
      },

      {
        title: ["  RTU Programming "],
        moduless1: [
          "Introduction",
          "Preparing process data to be delivered to the SCADA software",
          "Equipment’s Status",
          "Process Variables",

          ,
        ],
      },
    ],
  },
  {
    appId: 27,
    appName: "C/C++",
    imageUrl: "../images/c.png",
    backinformation: "Programming languages for software development.",

    category: "COMP",
    describe:
      "A C/C++ programming internship is a practical learning opportunity designed for students and programming enthusiasts. It allows participants to apply their theoretical knowledge to real-world projects under the guidance of experienced professionals. The internship provides exposure to the intricacies of coding, debugging, and building software applications using C and C++ programming languages.",
    Coursescontent: [
      {
        title: ["    Introduction  "],
        introduction: [
          "   C Language",
          "Introduction and Basics of C",
          "Data Types and Variables",
          "Control Flow Statements",
          "Formatted IO functions",
          "Functions and Program Structure",
          "Arrays, Pointers",
        ],
      },
      {
        title: ["   Modules  "],

        module: [
          "  Introduction of C++",
          "Data Types and Variables",
          "OOPS Concepts",
          "Classes and Objects",
          "Pointers and Functions",
          "Constructor and Destructor",
        ],
      },
    ],
  },
  {
    appId: 28,
    appName: "MS-OFFICE",
    imageUrl: "../images/msoffice.png",
    backinformation: "Suite of productivity software by Microsoft.",

    category: "COMP",
    describe:
      "The Microsoft Office publications we provide are designed to assist put together you for a profession in commercial enterprise administration, facts management, file control, and workplace productivity, providing mastery in vital equipment like Word, Excel, PowerPoint, and Outlook.",
    Coursescontent: [
      {
        title: ["    Text Basics  "],
        introduction: [
          "Typing the text",
          " Alignment of text,",
          "Editing Text: Cut, Copy, Paste, Select All, Clear",
          " Find & Replace",
        ],
      },
      {
        title: ["  Text Formatting and saving file  "],
        module: [
          " New, Open, Close, Save, Save As",
          " Formatting Text: Font Size, Font Style",
          "Font Color, Use the Bold, Italic, and Underline",
          "Change the Text Case",
          "Line spacing, Paragraph spacing,",
        ],
      },
      {
        title: ["    Working with Objects "],
        moduless: [
          "Shapes, Clipart and Picture, Word Art, Smart Art",
          " Columns and Orderings ",
          "Change the Order of Objects",
          " Page Number, Date & Time",
          " Inserting Text boxes",
        ],
      },
      {
        title: ["     Header & Footers "],
        moduless1: [
          " Inserting custom Header and Footer",
          "Inserting objects in the header and footer",
          "Add section break to a document",
        ],
      },
    ],
  },

  {
    appId: 29,
    appName: "TALLY",
    imageUrl: "../images/tally.png",
    backinformation: "Accounting software for businesses and individuals.",

    category: "COMP",
    describe:
      "Tally  is a popular and powerful accounting software widely used by businesses of all sizes to manage their financial data efficiently. this comprehensive software offers a range of features and capabilities that make it a reliable and user-friendly choice for accounting and financial management tasks.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Fundamentals of Tally",
          "Introduction to Accounting",
          "ERP 9 Fundamentals",
        ],
      },
      {
        title: ["  Modules"],
        module: [
          "  Accounting and Inventory in Tally E.R.P 9",
          "  Generating Basic Accounting Reports",
          "  Generating Basic Inventory Reports",
          "   Accounting Masters",
          "Inventory Masters",
          "         Voucher Entry",
        ],
      },

      {
        title: ["Advance topics"],
        moduless: [
          " Advanced Accounting Features",
          "Bill-Wise Details",

          "Cost Centres and Cost Categories",
          "Additional Cost to Purchase",
        ],
      },
    ],
  },
  {
    appId: 30,
    appName: "PHOTOSHOP",
    imageUrl: "../images/photoshop.png",
    backinformation: "Image editing software for graphic designers.",

    category: "COMP",
    describe:
      "Graphic design software used for enhancing and editing images, including architectural visualizations.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Photoshop Workspace",
          "Importing",
          " Working with Layers",
          "Selecting and Transforming",
        ],
      },
      {
        title: ["  Modules"],
        module: [
          "Brushes with Masks",
          "Fill and Paint Bucket Tool",
          "Shape Tool",
        ],
      },

      {
        title: ["Design"],
        moduless: [
          " Custom Shapes",
          "Clone Stamp Tool",
          "Collaging & Editing Drawings",
        ],
      },
    ],
  },
  {
    appId: 31,
    appName: "DCA",
    imageUrl: "../images/dca.png",
    backinformation: "Diploma in Computer Applications course.",

    category: "COMP",
    describe:
      "DCA or Diploma in Computer Applications, is a 1-year diploma course in the field of Computer Applications that involves the study of numerous computer applications such as MS Office, Internet Applications, Operating System, Database Management System (DBMS), HTML among other subjects.",
    Coursescontent: [
      {
        title: ["   Introductions  "],
        introduction: [
          " Fundamentals of Computer Operations",
          "computer literacy",
          " software skills",
          " generally flexible",
        ],
      },
      {
        title: ["   DCA feature  "],
        module: [
          "Lower cost",
          "Ride out market downturns",
          "Disciplined saving",
          "Higher transaction costs",
        ],
      },
      {
        title: ["  DCA TYPES "],
        moduless: [
          "  Basic Computer Skills.",
          "MS Office Applications.",
          "Software Hacking and IT Security.",
          "ERP Basics.",
          "Internet Basics.",
          "E-Business.",
        ],
      },
    ],
  },
  {
    appId: 32,
    appName: "PGDCA",
    imageUrl: "../images/pgdcad.png",
    backinformation: " Post Graduate Diploma in Computer Applications",

    category: "COMP",
    describe:
      "The PGDCA route covers complete expertise of pc software and pc science. PGDCA covers diverse topics, which includes pc programming, gadget analysis, net development, and software program engineering. The PGDCA route is designed to equip the scholar with theoretical expertise and sensible capabilities which can be required for running withinside the IT industry.",
    // Coursescontent: [
    //   {
    //     title: ["Introduction"],
    //     introduction: [
    //      ' PGDCA introduction',' web applications ','database management systems' , 'operating systems' ,
    //     ],
    //   },
    //   {
    //     title: ["  Input/Output & Storage Units-"],
    //     module: [

    //       ' Keyboard',' Mouse',' Trackball',' Joystick', 'Digitizing tablet', 'canners', 'DigitalCamera',
    //     ],
    //   },

    //   {
    //     title: ["Fundamentals"],
    //     moduless: [

    //      ' Dot Matrix', 'Inkjet', 'Laser', 'Plotter',
    //      'Sequential', 'Direct And Index Sequential',

    //     ],
    //   },
    // ],

    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Fundamentals of Information Technology.",
          "  Visual Basics",
          "C Programming",
          "Basic Java",
          " Soft Skills",
          " Database management system",
          "Data Structure and Algorithms",
          "Software engineering and business process ",
        ],
      },
      {
        title: ["Subject"],
        introduction: [
          "Computer Fundemental.",
          "  Ms-Dom & window",
          "Ms-office",
          "C,C++Programming",
          "Internet",
          "HTML",
          "Practical",
        ],
      },
    ],
  },

  {
    "appId": 48,
    "appName": "GD&T",
    "imageUrl": "../images/gdt.png",
    "backinformation": "System for defining and communicating tolerances on part geometry.",
  
    "category": "MECH",
    "describe": 
      "GD&T (Geometric Dimensioning and Tolerancing) is a crucial system in mechanical engineering that uses symbolic language to describe the permissible limits of variation in the form, orientation, and location of features on manufactured parts. By attending SHAW TECH's GD&T training, students will learn from industry experts about interpreting engineering drawings, understanding the principles of GD&T, and applying these standards in real-world scenarios. The training includes hands-on experience, enabling learners to practically apply their knowledge and better prepare for careers in the manufacturing and engineering sectors. SHAW TECH also offers additional resources, including internships and industrial visits, to further enhance the learning experience.",
    "Coursescontent": [
      {
        "title": ["Introduction to GD&T"],
        "introduction": [
          "Basics of Engineering Drawings",
          "Introduction to GD&T Symbols",
          "Importance of GD&T in Manufacturing",
          "Standards and Conventions",
          "Benefits of Using GD&T"
        ]
      },
      {
        "title": ["Key GD&T Concepts"],
        "module": [
          "Datums and Datum Features",
          "Form Tolerances (Flatness, Straightness, Circularity, Cylindricity)",
          "Orientation Tolerances (Perpendicularity, Parallelism, Angularity)",
          "Location Tolerances (Position, Concentricity, Symmetry)"
        ]
      },
      {
        "title": ["GD&T Applications"],
        "moduless": ["Real-World Applications", "Interpretation of GD&T in Drawings"]
      }
    ]
  }
,  
{
  "appId": 49,
  "appName": "3DS Max",
  "imageUrl": "../images/3D.png",
  "backinformation": "Software for creating 3D models, visualizations, and animations in architecture.",

  "category": "CIVIL",
  "describe": 
    "3DS Max is a powerful software used in civil engineering and architecture for creating detailed 3D models, visualizations, and animations of structures and designs. At SHAW TECH, students will receive comprehensive training on 3DS Max, learning how to use the software to create realistic models and renderings that can be used in presentations and project planning. The training covers essential tools and techniques, allowing students to master the software for both professional and academic purposes. Alongside classroom instruction, students have opportunities to work on real-world projects, internships, and attend industrial visits to see how 3DS Max is applied in the industry.",
  "Coursescontent": [
    {
      "title": ["Introduction to 3DS Max"],
      "introduction": [
        "Overview of 3DS Max Interface",
        "Basics of 3D Modeling",
        "Navigating the Viewport",
        "Introduction to Materials and Textures",
        "Lighting and Rendering Basics"
      ]
    },
    {
      "title": ["Modeling Techniques"],
      "module": [
        "Creating and Modifying Objects",
        "Working with Splines",
        "Advanced Modeling Techniques",
        "Boolean Operations",
        "Using Modifiers"
      ]
    },
    {
      "title": ["Rendering and Visualization"],
      "moduless": ["Setting Up Cameras", "Lighting Techniques", "Rendering Settings", "Post-Processing"]
    }
  ]
}
,
{
  "appId": 50,
  "appName": "QTO",
  "imageUrl": "../images/qto.jpg",
  "backinformation": "Process for estimating quantities of materials, labor, and equipment for construction.",

  "category": "CIVIL",
  "describe": 
    "QTO (Quantity Takeoff) is a critical process in civil engineering that involves estimating the quantities of materials, labor, and equipment needed for a construction project. At SHAW TECH, the QTO training program teaches students how to accurately assess and calculate these quantities from project drawings and specifications. The course emphasizes practical application, preparing students to handle real-world projects efficiently. In addition to classroom instruction, SHAW TECH provides opportunities for internships, industrial visits, and hands-on experience, ensuring that students are well-equipped to contribute effectively to the construction industry.",
  "Coursescontent": [
    {
      "title": ["Introduction to QTO"],
      "introduction": [
        "Fundamentals of Quantity Takeoff",
        "Understanding Construction Drawings",
        "Introduction to Estimation Techniques",
        "Tools and Software for QTO",
        "Importance of Accuracy in QTO"
      ]
    },
    {
      "title": ["QTO Techniques"],
      "module": [
        "Calculating Material Quantities",
        "Estimating Labor Requirements",
        "Assessing Equipment Needs",
        "Cost Estimation Basics",
        "QTO Reporting"
      ]
    },
    {
      "title": ["QTO Applications"],
      "moduless": ["Case Studies", "Real-World Project Examples", "Advanced QTO Techniques"]
    }
  ]
}
,
  {
    appId: 33,
    appName: "ADVANCE PGDCA",
    imageUrl: "../images/pgdcad.png",
    backinformation: "Advanced Post Graduate Diploma in Computer Applications.",

    category: "COMP",
    describe:
      "The PGDCA route covers complete expertise of pc software and pc science. PGDCA covers diverse topics, which includes pc programming, gadget analysis, net development, and software program engineering. The PGDCA route is designed to equip the scholar with theoretical expertise and sensible capabilities which can be required for running withinside the IT industry.",
    Coursescontent: [
      {
        title: ["Introduction"],
        introduction: [
          "Fundamentals of Information Technology.",
          "  Visual Basics",
          "C Programming",
          "Basic Java",
          " Soft Skills",
          " Database management system",
          "Data Structure and Algorithms",
          "Software engineering and business process ",
        ],
      },
      {
        title: ["Subject"],
        introduction: [
          "Computer Fundemental.",
          "  Ms-Dom & window",
          "Ms-office",
          "C,C++Programming",
          "Internet",
          "Tally 9.0",
          "HTML",
          "Practical",
        ],
      },
    ],
  },

  


];

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if admin is logged in from sessionStorage
    const isAdminLoggedIn = sessionStorage.getItem("isAdminLoggedIn");
    if (isAdminLoggedIn) {
      setIsAdmin(true);
      setIsLoggedIn(true); // Assuming admin login also means user is logged in
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    setIsAdmin(true);
    sessionStorage.setItem("isAdminLoggedIn", "true");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    sessionStorage.removeItem("isAdminLoggedIn");
  };
  return (
    <>
      <Router>
      <Popupform/>
        {/* <StudentForm/> */}
        <Topheader
          isLoggedIn={isLoggedIn}
          isAdmin={isAdmin}
          onLogout={handleLogout}
        />
        <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/teamdetails" element={<TeamDetails />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/Courses" element={<Courses />} />
          <Route path="/itcunsultancy" element={<ItCunsultancy />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projectsdetails" element={<ProjectDetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/display" element={<DisplayJobs />} />
          {/* Dynamic route for token */}
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* <Route path="/Careers" element={<Careers />} /> */}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Topheader" element={<Topheader />} />
          <Route path="/applyjob" element={<ApplyJobForm />} />
          <Route path="/Leftbar" element={<Leftbar />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/marksheet" element={<AddMarksheetForm />} />
          <Route path="/certificate" element={<Studentform1 />} />
          <Route path="/certificateview" element={<CertificateDetaile />} />
          <Route path="/applicants" element={<ApplicantList />} />
          <Route path="/job" element={<Jobdescription />} />
          <Route path="/view" element={<ViewMarksheets />} />
          <Route path="/receipt" element={<Receipt />} />
          <Route path="/addreceipt" element={<AddReceipt />} />
          {/* <Route path="/edit/:id" element={<EditReceipt />} /> */}
          <Route exact path="/feedback" element={<Feedback />} />
          <Route exact path="/new-receipt" component={Newecepit} />
          <Route exact path="/edit-receipt/:id" component={EditReceipt} />
          <Route exact path="/stud" element={<Student />} />
          <Route exact path="/student" element={<Studentview />} />
          <Route exact path="/specializations" element={<Specializations/>} />
          {/* <Route
            exact
            path="/detailes/:regNo"
            element={<StudentDetails />}
          /> */}

          <Route
            path="/CoursesDetails/:appName"
            element={<CoursesDetails appsList={appsList} />}
          />
        </Routes>
      </Router>
      <Footer />
      <Enquiry />
      <E1 />
      
      {/* <StudentForm /> */}
      <ScrollTopTopButton />
      <WhatsAppButton />
    </>
  );
};

export default App;
